import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

export default function () {
  return function WithCustomPlaceholderHOC(WrappedComponent) {
    class WithCustomPlaceholder extends React.Component {
      constructor() {
        super();
        this.state = { isPlaceholderVisible: true };
      }

      componentWillMount() {
        this.handlePlaceholderVisibility();
      }

      componentWillReceiveProps(nextProps) {
        const { value, input } = this.props;
        const { value: nextValue, input: nextInput } = nextProps;
        if ((value || (input && input.value)) && !nextValue && (!nextInput || !nextInput.value)) {
          this.setState({ isPlaceholderVisible: true });
        } else if (
          (nextValue || (nextInput && nextInput.value)) && !value && (!input || !input.value)
        ) {
          this.setState({ isPlaceholderVisible: false });
        }
      }

      getInputRef = (ref) => {
        if (ref) {
          // eslint-disable-next-line
          this.inputRef = findDOMNode(ref).getElementsByTagName('input')[0];
        }
      }

      handlePlaceholderVisibility = () => {
        const { value, input } = this.props;
        if (value || (input && input.value)) {
          this.setState({ isPlaceholderVisible: false });
        } else {
          this.setState({ isPlaceholderVisible: true });
        }
      }

      handleClickOutside = () => {
        this.handlePlaceholderVisibility();
      }

      handleFocus = () => {
        this.setState({ isPlaceholderVisible: false });
        if (this.inputRef) { this.inputRef.focus(); }
      }

      render() {
        const { PlaceholderComponent, ...otherProps } = this.props;
        const { isPlaceholderVisible } = this.state;

        return (
          PlaceholderComponent
            ? (
              <Container ref={this.getInputRef}>
                <WrappedComponent onFocus={this.handleFocus} {...otherProps} />
                {isPlaceholderVisible
                  ? (
                    <PlaceholderContainer onClick={this.handleFocus}>
                      {PlaceholderComponent}
                    </PlaceholderContainer>
                  )
                  : null
              }
              </Container>
            )
            : <WrappedComponent {...this.props} />
        );
      }
    }

    WithCustomPlaceholder.propTypes = {
      value: PropTypes.string,
      input: PropTypes.shape({
        value: PropTypes.string,
      }),
      PlaceholderComponent: PropTypes.node,
    };

    WithCustomPlaceholder.defaultProps = {
      value: null,
      input: null,
      PlaceholderComponent: null,
    };

    return onClickOutside(WithCustomPlaceholder);
  };
}

const Container = styled.div`
  position: relative;
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;
