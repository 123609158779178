import React from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import { LIGHT_GRAY_1, MEDIUM_GRAY, DARK_GRAY } from '../../constants/colors';
import { LAPTOP, TABLET } from '../../constants/screens';
import withReduxFormInput from '../../enhancers/withReduxFormInput';
import withCustomPlaceholder from '../../enhancers/withCustomPlaceholder';

const TextArea = props => (
  <Input
    placeholder=""
    {...props}
  />
);

TextArea.propTypes = {
  minHeight: PropTypes.string,
};

TextArea.defaultProps = {
  minHeight: '',
};

const Input = styled.textarea`
  outline: none;
  resize: vertical;
  box-sizing: border-box;
  padding-left: 25px;
  min-height: ${props => props.minHeight};
  border-radius: 6px;
  border: 1px solid ${MEDIUM_GRAY};
  background-color:  ${LIGHT_GRAY_1};
  font-family: AvenirNextLTProBold;
  font-size: 21px;
  line-height: normal;
  margin-bottom: 15px;
  padding-top: 20px;
  width: 100%;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  &::-webkit-input-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  &::-moz-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  &:-ms-input-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  &:-moz-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
`;

export default compose(
  withReduxFormInput(),
  withCustomPlaceholder(),
)(TextArea);
