import * as React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>
    {element}
  </Layout>
);

wrapPageElement.propTypes = {
  element: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  props: PropTypes.node.isRequired,
};

export default wrapPageElement;
