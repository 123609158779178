// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-consulting-enquiry-jsx": () => import("./../src/pages/consulting-enquiry.jsx" /* webpackChunkName: "component---src-pages-consulting-enquiry-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-custom-research-enquiry-jsx": () => import("./../src/pages/custom-research-enquiry.jsx" /* webpackChunkName: "component---src-pages-custom-research-enquiry-jsx" */),
  "component---src-pages-custom-research-jsx": () => import("./../src/pages/custom-research.jsx" /* webpackChunkName: "component---src-pages-custom-research-jsx" */),
  "component---src-pages-data-dashboards-jsx": () => import("./../src/pages/data-dashboards.jsx" /* webpackChunkName: "component---src-pages-data-dashboards-jsx" */),
  "component---src-pages-developer-program-benchmarking-jsx": () => import("./../src/pages/developer-program-benchmarking.jsx" /* webpackChunkName: "component---src-pages-developer-program-benchmarking-jsx" */),
  "component---src-pages-developer-research-jsx": () => import("./../src/pages/developer-research.jsx" /* webpackChunkName: "component---src-pages-developer-research-jsx" */),
  "component---src-pages-free-resources-jsx": () => import("./../src/pages/free-resources.jsx" /* webpackChunkName: "component---src-pages-free-resources-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-methodology-jsx": () => import("./../src/pages/methodology.jsx" /* webpackChunkName: "component---src-pages-methodology-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-reports-jsx": () => import("./../src/pages/reports.jsx" /* webpackChunkName: "component---src-pages-reports-jsx" */),
  "component---src-pages-technology-trackers-jsx": () => import("./../src/pages/technology-trackers.jsx" /* webpackChunkName: "component---src-pages-technology-trackers-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-post-page-jsx": () => import("./../src/templates/postPage.jsx" /* webpackChunkName: "component---src-templates-post-page-jsx" */)
}

