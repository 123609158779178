import React from 'react';
import { pathOr } from 'ramda';
import TextInput from '../components/common/textInput';
import Checkbox from '../components/common/checkBox';
import Textarea from '../components/common/textArea';
import Dropdown from '../components/common/dropdown';
import { numberOnly } from './form';
import countries from '../constants/countries';

export const getComponentByType = (type) => {
  switch (type) {
    case 'text':
      return TextInput;
    case 'checkbox':
      return Checkbox;
    case 'radio':
      return props => <input type="radio" {...props} />;
    case 'textarea':
      return Textarea;
    case 'dropdown':
      return Dropdown;
    case 'countries':
      return props => <Dropdown {...props} options={countries} />;
    default:
      return TextInput;
  }
};

export const getConstraints = (c) => {
  switch (c) {
    case 'numbersOnly':
      return [numberOnly];
    default:
      return null;
  }
};

export const mapButtonsToTabs = buttons => buttons.map(
  b => ({ node: { name: b.text, buttonIcon: { file: { localFile: { svgIcon: b.icon } } } } }),
);

export const getImageFluid = pathOr({}, ['file', 'localFile', 'childImageSharp', 'fluid']);
export const getImageSizes = pathOr({}, ['file', 'localFile', 'childImageSharp', 'sizes']);

export const log = (items) => {
  // eslint-disable-next-line
  console.log(items);
  return items;
};

// eslint-disable-next-line
export const stringToBoolean = s => (s === 'true' ? true : (s === 'false' ? false : s));

// eslint-disable-next-line
export const checkUrl = url => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(url)
