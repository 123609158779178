import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Logo from '../common/logo';
import LinkList from './linkList';
import { BLUE, LOGO_DARK_COLORS, WHITE } from '../../constants/colors';
import {
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media, CONTAINER_LAPTOP_WIDTH,
} from '../../constants/screens';
import SmartLink from '../common/smartLink';

const Footer = ({
  data: {
    socials, copyright, menuItem, logoLink, address: { html },
  },
}) => (
  <Root>
    <MainContent>
      <ContentTop>
        <LogoContainer>
          <StyledLogo colors={LOGO_DARK_COLORS} logoLink={logoLink} />
          <Text dangerouslySetInnerHTML={{ __html: html }} />
        </LogoContainer>
        <Links>
          {menuItem.map(({ title, navLink, id }) => (
            <LinkList
              key={id}
              title={title}
              links={navLink}
            />
          ))}
        </Links>
      </ContentTop>
      <ContentBottom>
        <IconsWrapper>
          {
            socials.map(({
              _id, alt, url, icon: { localFile: { publicURL } },
            }) => (
              <IconWrapper
                key={_id}
                href={url}
              >
                <img src={publicURL} alt={alt} />
              </IconWrapper>
            ))
            }
        </IconsWrapper>
        <Info>
          <Title>
            {copyright.title}
          </Title>
          <TextInfo>
            {copyright.items.map(({ title, url, _id }, i) => {
              const item = url ? <BottomLink to={url}>{title}</BottomLink> : title;
              return (
                <span key={_id}>
                  {item}
                  {i !== copyright.items.length && ' | '}
                </span>
              );
            })}
          </TextInfo>
        </Info>
      </ContentBottom>
    </MainContent>
  </Root>
);

Footer.propTypes = {
  data: PropTypes.shape().isRequired,
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 190px 5% 85px 5%;
  background-color: ${WHITE};
  
  ${media.laptop`
    padding: 140px 5% 60px 5%;
  `}

  @media screen and (max-width: ${TABLET}) {
    padding: 75px 10% 70px;
  }

  @media screen and (max-width: ${MOBILE}) {
    padding: 50px 15% 45px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;


const LogoContainer = styled.div`
  margin-right: 40px;

  @media screen and (max-width: ${TABLET}) {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    margin-right: 0;

    > p, > br {
      display: none;
    }
  }
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 30px;

  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 80px;
  }
`;

const Text = styled.p`
  display: block;
  white-space: pre-line;
  color: ${BLUE};
  max-width: 180px;
  width: 100%;
  line-height: 1.3;
  font-size: 17px;
  
  ${media.laptop`
    font-size: 14px;
  `}
  
  & a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: ${LAPTOP}) {
    font-size: 15px;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${TABLET}) {
    justify-content: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    width: 100%;

    > div {
      width: 50%;
      margin-bottom: 40px;
      box-sizing: border-box;

      &:nth-child(2n) {
        padding-left: 5px;
      }

      &:nth-child(2n - 1) {
        padding-right: 5px;
      }
    }
  }

  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 25px;
    width: 100%;
  }
`;

const ContentTop = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 95px;

  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const ContentBottom = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const IconWrapper = styled.a`
  height: 35px;
  cursor: pointer;
  
  @media screen and (max-width: ${LAPTOP}) {
    height: 28px;
  }

  @media screen and (max-width: ${TABLET}) {
    height: 20px;
  }

  @media screen and (max-width: ${MOBILE}) {
    height: 30px;
  }
  
  & > img {
    max-width: 100%;
    height: 100%;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & ${IconWrapper}:not(:last-child) {
    margin-right: 30px;
  }

  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 70px;

    > div:not(:last-child) {
      margin-right: 85px;
    }
  }

  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 60px;
  
    > div:not(:last-child) {
      margin-right: 35px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 19px;
  text-align: right;
  text-transform: uppercase;
  color: ${BLUE};
  margin-bottom: 10px;

  @media screen and (max-width: ${LAPTOP}) {
    font-size: 16px;
  }

  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
  }
`;

const TextInfo = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 17px;
  line-height: normal;
  text-align: right;
  color: ${BLUE};

  @media screen and (max-width: ${LAPTOP}) {
    font-size: 14px;
  }

  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 10px;
  }
`;

const BottomLink = styled(SmartLink)`
  margin: 0 5px;
  text-decoration: none;
  color: ${BLUE};
`;


export default Footer;
