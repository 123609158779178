/* eslint-disable no-bitwise,no-param-reassign */
export const getPropertyValue = (pipeline, initialValue, props) => pipeline.reduceRight(
  (res, item) => {
    if (props[item.key]) {
      return item.true || res;
    }
    return item.false || res;
  }, initialValue,
);

export function addOpacity(color, opacity) {
  const rgb = hex2rgb(color);
  return `${rgb.slice(0, -1)}, ${opacity})`;
}

export function hex2rgb(hex) {
  if (hex.length === 3) {
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }

  const arr = [`0x${hex[1]}${hex[2]}` | 0, `0x${hex[3]}${hex[4]}` | 0, `0x${hex[5]}${hex[6]}` | 0];

  return `rgb(${arr[0]}, ${arr[1]}, ${arr[2]})`;
}
