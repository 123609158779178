import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { BLUE } from '../../constants/colors';
import { MOBILE, TABLET, media } from '../../constants/screens';
import SmartLink from '../common/smartLink';

const LinkList = ({ title, links }) => (
  <Root>
    <Title>
      {title}
    </Title>
    {links.map(({ id, url, title: label }) => (/https?:\//.test(url)
      ? (
        <StyledA
          key={id}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </StyledA>
      ) : (
        <StyledLink key={id} to={url}>
          {label}
        </StyledLink>
      )))}
  </Root>
);

LinkList.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
};

const Root = styled.div`
  flex: 0.15;
  color: ${BLUE};
  margin-right: 40px;
  
  &:last-child {
    margin-right: 0;
  }
  
  @media screen and (max-width: ${TABLET}) {
    width: 45%;
    flex: auto;
    margin: 0;
  }
  
  @media screen and (max-width: 905px) {
    width: 100%;
  }
`;

const Title = styled.label`
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: AvenirNextLTProBold;
  
  ${media.laptop`
    font-size: 18px;
  `};
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 16px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
  }
`;

const linkCss = css`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: ${BLUE};
  margin-top: 15px;
  font-size: 17px;
  
  ${media.laptop`
    font-size: 14px;
    margin-top: 12px;
  `};
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 16px;
    margin-top: 15px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
    margin-top: 10px;
  }
`;

const StyledLink = styled(SmartLink)`
  ${linkCss}
`;

const StyledA = styled.a`
  ${linkCss}
`;

export default LinkList;
