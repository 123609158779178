import { pathOr } from 'ramda';
import wrapWithLayout from './src/gatsby/wrapPageElement';
import wrapWithProvider from './src/gatsby/wrapRootElement';
import { pageview, set } from './src/utils/analytics';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithLayout;

export const onRouteUpdate = ({ location: { pathname, search } }) => {
  if (typeof window !== 'undefined' && pathOr(false, ['Cookiebot', 'consent', 'statistics'], window)) {
    set({ page: `${window.location.origin}${pathname}${search}` });
    pageview(`${window.location.origin}${pathname}${search}`);
  }
};
