import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import LogoIcon from '../../svg/logo.svg';
import BlogLogoIcon from '../../svg/logo-blog.svg';
import { LAPTOP, TABLET } from '../../constants/screens';

const Logo = ({
  colors, className, isBlog, logoLink, blogLogoLink,
}) => {
  // eslint-disable-next-line
  const StyledLogo = isBlog ? IconBlog : Icon;
  const path = isBlog ? blogLogoLink : logoLink;

  return (
    <StyledLink to={path}>
      <StyledLogo className={className} colors={colors} />
    </StyledLink>
  );
};

// eslint-disable-next-line
const Icon = styled(({ colors, ...props }) => <LogoIcon {...props} />)`
  & path:first-child {
    fill: ${({ colors }) => colors[0]};
  }
  
  & path:last-child {
    fill: ${({ colors }) => colors[1]};
  }
  
  width: 155px;
    
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    width: 130px;
  }
    
  @media screen and (max-width: ${TABLET}) {
    width: 100px;
    height: auto;
  }
`;

// eslint-disable-next-line
const IconBlog = styled(({ colors, ...props }) => <BlogLogoIcon {...props} />)`
  & path {
    fill: ${({ colors }) => colors[0]}; 
  }

  & path:first-child {
    fill: ${({ colors }) => colors[0]};
  }
  
  & path:nth-child(2),
  & path:last-child {
    fill: ${({ colors }) => colors[1]};
  }
    
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    width: 240px;
  }
    
  @media screen and (max-width: ${TABLET}) {
    width: 160px;
    height: auto;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

Logo.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  isBlog: PropTypes.bool,
  logoLink: PropTypes.string,
  blogLogoLink: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
  isBlog: false,
  logoLink: '/',
  blogLogoLink: '/blog',
};

export default Logo;
