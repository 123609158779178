import validatejs, { validators } from 'validate.js';
import { merge } from 'ramda';
import { reduceObject } from './common';

const presence = (value, { message }) => (value && value.trim() ? undefined : (message || 'can\'t be blank'));

validatejs.validators = merge(validators, {
  presence,
});

export const validate = constraints => values => validatejs(values, constraints);

export const oneRequired = (values, message) => (errors) => {
  const key = Object.keys(values)[0];
  const missing = reduceObject((res, k, v) => (v ? res : [...res, k]), [])(values[key]);

  if (missing.length === Object.keys(values[key]).length) {
    if (errors && errors[key]) {
      return {
        ...errors,
        [key]: [
          ...errors[key],
          message || 'At least one option must be chosen',
        ],
      };
    }
    return {
      ...errors,
      [key]: [message || 'At least one option must be chosen'],
    };
  }
  return errors;
};
