import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DARK_RED, PINK } from '../../constants/colors';
import { media, CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH } from '../../constants/screens';

const Banner = ({
  onJoinClick,
  onCancelClick,
  bannerVisible,
  title,
}) => (
  <Container bannerVisible={bannerVisible}>
    <Text>
      {title}
      <Button onClick={onJoinClick}>Join now</Button>
      <Button onClick={onCancelClick}>No, thanks</Button>
    </Text>
  </Container>
);

Banner.propTypes = {
  onJoinClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  bannerVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: ${props => (props.bannerVisible ? '300px' : '0px')};
  width: 100%;
  background: ${PINK};
  box-sizing: border-box;
  font-family: AvenirNextLTPro;
  font-size: 14px;
  transition: max-height .2s ease-out;
  overflow: hidden;
`;

const Text = styled.span`
  display: inline-block;
  max-width: calc(${CONTAINER_WIDTH} + 40px);
  padding: 15px 20px;
  
  ${media.laptop`
    max-width: calc(${CONTAINER_LAPTOP_WIDTH} + 40px);
  `}
`;

const Button = styled.button`
  font-family: AvenirNextLTProBold;
  border: none;
  background: transparent;
  color: ${DARK_RED};
  font-size: 14px;
  padding: 0;
  margin-left: 5px;
  cursor: pointer;
  
  & + button {
    margin-left: 10px;
  }
  
  &:hover {
    text-decoration: underline;
  }
`;

export default Banner;
