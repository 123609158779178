import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Header from './header';

export default props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        header: header {
          logoLink
          blogLogoLink
          menuItem {
            id: _id
            title
            url
            visibleIn {
              blogPart
              mainPart
            }
            navLink {
              id: _id
              title
              url
            }
          }
        }
        banner: banner {
          showBanner
          pagesToDisplay {
            url
          }
          title
          link
        }
      }
    `}
    render={({ header, banner }) => <Header {...props} data={header} bannerData={banner} />}
  />
);
