import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TABLET } from '../../constants/screens';

const Icon = ({
  component, color, width, height, onClick, shouldBeFixed, widthUnfixed, heightUnfixed,
}) => {
  const StyledComponent = styled(component)`
    display: none;
    width: ${shouldBeFixed ? width : widthUnfixed};
    height: ${shouldBeFixed ? height : heightUnfixed};
    background-color: transparent;
    cursor: pointer;
  
    & path {
      fill: ${props => props.color}
    }
  
    @media (max-width: ${TABLET}) {
      display: block;
    }
  `;

  return (
    <StyledComponent
      color={color}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};

Icon.propTypes = {
  component: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  shouldBeFixed: PropTypes.bool,
  widthUnfixed: PropTypes.string,
  heightUnfixed: PropTypes.string,
};

Icon.defaultProps = {
  width: undefined,
  height: undefined,
  widthUnfixed: undefined,
  heightUnfixed: undefined,
  onClick: () => null,
  shouldBeFixed: false,
};

export default Icon;
