import styled from 'styled-components';
import { TABLET } from '../../constants/screens';

const Arrow = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')}
  width: 0px;
  height: 0px;
  border-top: ${({ height, color, direction }) => (direction !== 'down' ? `${height / 2}px solid transparent` : `${height / 2}px solid ${color}`)};
  border-bottom: ${({ height, color, direction }) => (direction !== 'up' ? `${height / 2}px solid transparent` : `${height / 2}px solid ${color}`)};
  border-right: ${({ width, color, direction }) => (direction !== 'left' ? `${width / 2}px solid transparent` : `${width / 2}px solid ${color}`)};
  border-left: ${({ width, color, direction }) => (direction !== 'right' ? `${width / 2}px solid transparent` : `${width / 2}px solid ${color}`)};
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: 1;
  
  @media(max-width: ${TABLET}) {
    display: none;
  }
`;

export default Arrow;
