import { css } from 'styled-components';

export const LAPTOP = '1550px';
export const TABLET = '1024px';
export const MOBILE = '500px';

export const CONTAINER_WIDTH = '1400px';
export const CONTAINER_LAPTOP_WIDTH = '1000px';

const sizes = {
  laptop: LAPTOP,
  tablet: TABLET,
  mobile: MOBILE,
};

const onlySizes = {
  laptop: {
    from: LAPTOP,
    to: TABLET,
  },
  tablet: {
    from: TABLET,
    to: MOBILE,
  },
};

// Iterate through the sizes and create a media template
export const media = {
  ...Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}) {
      ${css(...args)}
    }
  `;

    return acc;
  }, {}),
  only: Object.keys(onlySizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${onlySizes[label].from}) and (min-width: ${onlySizes[label].to}) {
      ${css(...args)}
    }
  `;

    return acc;
  }, {}),
};
