import {
  BLACK,
  BLUE,
  DARK_SCHEME,
  LIGHT_SCHEME,
  LOGO_DARK_COLORS,
  LOGO_LIGHT_COLORS,
  TURQUOISE,
  WHITE,
} from '../../constants/colors';
import { getPropertyValue } from '../../utils/component';

const getColorByScheme = (scheme, options) => {
  switch (scheme) {
    case LIGHT_SCHEME:
      return options[LIGHT_SCHEME];
    case DARK_SCHEME:
      return options[DARK_SCHEME];
    default:
      return options.default;
  }
};

export const getLogoColors = (props) => {
  const { scheme } = props;

  const initialColor = getColorByScheme(scheme, {
    [LIGHT_SCHEME]: LOGO_LIGHT_COLORS,
    [DARK_SCHEME]: LOGO_DARK_COLORS,
    default: null,
  });

  return getPropertyValue(
    [{ key: 'expanded', true: LOGO_DARK_COLORS }],
    initialColor,
    props,
  );
};

export const getFontColor = (props) => {
  const { scheme } = props;

  const initialColor = getColorByScheme(scheme, {
    [LIGHT_SCHEME]: WHITE,
    [DARK_SCHEME]: BLACK,
    default: BLACK,
  });

  return getPropertyValue(
    [{ key: 'expanded', true: '#000' }],
    initialColor,
    props,
  );
};

export const getArrowColor = (props) => {
  const { scheme } = props;

  const initialColor = getColorByScheme(scheme, {
    [LIGHT_SCHEME]: TURQUOISE,
    [DARK_SCHEME]: BLUE,
    default: BLACK,
  });

  return getPropertyValue(
    [{ key: 'expanded', true: BLUE }],
    initialColor,
    props,
  );
};

export const getButtonColor = (props) => {
  const { scheme } = props;

  const initialColor = getColorByScheme(scheme, {
    [LIGHT_SCHEME]: WHITE,
    [DARK_SCHEME]: BLUE,
    default: BLACK,
  });

  return getPropertyValue(
    [{ key: 'expanded', true: BLUE }],
    initialColor,
    props,
  );
};

export const getBackgroundColor = props => getPropertyValue(
  [
    { key: 'shouldBeFixed', true: WHITE },
    { key: 'expanded', true: WHITE },
  ],
  'transparent',
  props,
);
