export const LIGHT_SCHEME = 'light';
export const DARK_SCHEME = 'dark';

export const WHITE = '#ffffff';
export const LIGHT_GRAY_1 = '#f8f9fc';
export const LIGHT_GRAY_2 = '#f1f3f8';
export const MEDIUM_GRAY = '#dce3ee';
export const DARK_GRAY = '#bfd0e1';
export const DARK_GRAY_2 = '#9aaab0';
export const BLACK = '#332f2f';
export const BLUE = '#2b55c5';
export const PURPLE_BLUE = '#6b27e8';
export const DARK_BLUE = '#2a159a';
export const TURQUOISE = '#3bcdd5';
export const LIGHT_GREEN = '#ceead5';
export const PINK = '#f7d1d5';
export const DARK_RED = '#671a21';
export const DARK_GREEN = '#154d21';
export const ERROR_RED = '#ff0000';
export const DARK_BLUE_GRAY = '#1B0089';

export const LOGO_LIGHT_COLORS = [WHITE, WHITE];
export const LOGO_DARK_COLORS = [BLACK, BLUE];
