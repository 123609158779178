import React from 'react';
import PropTypes from 'prop-types';
import Error from '../components/common/error';

const defaultOptions = {
  getValue: value => value,
  setValue: value => value,
};

export default function (options = {}) {
  const { getValue, setValue } = { ...defaultOptions, ...options };

  return function WithReduxFormInputHOC(WrappedComponent) {
    class WithReduxFormInput extends React.Component {
      handleBlur = (event) => {
        const { relatedTarget } = event;
        const { input: { onBlur } } = this.props;
        if (relatedTarget && (relatedTarget.getAttribute('type')) === 'submit') {
          event.preventDefault();
        } else {
          onBlur();
        }
      };

      render() {
        const {
          errorStyle,
          showError,
          value: propsValue,
          input: {
            onChange,
            value,
            name,
          },
          meta: {
            error,
            touched,
          },
          ...otherProps
        } = this.props;

        return (
          <div>
            <WrappedComponent
              value={setValue(value, this.props)}
              onChange={val => onChange(getValue(val, propsValue, this.props))}
              onBlur={this.handleBlur}
              name={name}
              {...otherProps}
              style={error && touched && showError ? { border: '1px solid red' } : {}}
            />
            {error && touched && showError
              ? <Error styles={errorStyle}>{error}</Error>
              : null
            }
          </div>
        );
      }
    }

    WithReduxFormInput.defaultProps = {
      showError: true,
      value: null,
      input: null,
      errorStyle: null,
      meta: null,
    };

    WithReduxFormInput.propTypes = {
      value: PropTypes.string,
      input: PropTypes.shape({
        onBlur: PropTypes.func,
        value: PropTypes.string,
        name: PropTypes.string,
      }),
      errorStyle: PropTypes.instanceOf(Array),
      showError: PropTypes.bool,
      meta: PropTypes.shape({
        error: PropTypes.any,
        touched: PropTypes.bool,
      }),
    };

    return WithReduxFormInput;
  };
}
