import React from 'react';
import { Location } from '@reach/router';

export default function WithLocation(WrappedComponent) {
  return props => (
    <Location>
      {({ location, navigate }) => (
        <WrappedComponent {...props} location={location} navigate={navigate} />
      )}
    </Location>
  );
}
