/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from '../redux/createStore';

const store = createStore();

// eslint-disable-next-line
export default ({ element }) => (
  <Provider store={store}>
    {element}
  </Provider>
);
