import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import CheckedIcon from '../../svg/checked-icon.svg';
import UncheckedIcon from '../../svg/unchecked-icon.svg';
import { BLUE, DARK_GRAY } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const CheckBox = ({
  input, text, fontFamily, fontSize, markdownText,
}) => {
  const align = markdownText ? 'flex-start' : 'center';
  const margin = markdownText ? '0px' : '3px';

  return (
    <Label align={align}>
      <Input
        type="checkbox"
        name={input.name}
        onChange={input.onChange}
      />
      {input.value ? <CheckedIcon /> : <UncheckedIcon />}
      <Span
        fontFamily={fontFamily}
        fontSize={fontSize}
        margin={margin}
      >
        {text}
        {markdownText && <Markdown source={markdownText} />}
      </Span>
    </Label>
  );
};

const Label = styled.label`
  display: inline-flex;
  align-items: ${props => props.align};
  cursor: pointer;
  
  > svg {
    width: 26px;
    height: 26px;
  }
`;

const Input = styled.input`
  visibility: hidden;
  width: 0;
  margin: 0;
  height: 0;
`;

const Span = styled.span`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  line-height: normal;
  color: ${DARK_GRAY};
  margin-left: 10px;
  margin-top: ${props => props.margin};
  display: flex;
  flex-direction: column;
  flex: 1;
  
  a {
    color: ${BLUE};
    text-decoration: none;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: ${props => (props.fontSize === '21px' ? '18px' : '17px')};
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: ${props => (props.fontSize === '21px' ? '17px' : '15px')};
  }
`;

CheckBox.defaultProps = {
  fontFamily: 'AvenirNextLTProBold',
  fontSize: '21px',
  markdownText: '',
  text: '',
};

CheckBox.propTypes = {
  input: PropTypes.shape().isRequired,
  text: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  markdownText: PropTypes.string,
};

export default CheckBox;
