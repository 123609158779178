import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { checkUrl } from '../../utils';

const SmartLink = ({ to, children, ...rest }) => (checkUrl(to) ? (
  <a href={to} {...rest}>
    {children}
  </a>
) : (
  <Link to={to} {...rest}>
    {children}
  </Link>
));

SmartLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SmartLink.defaultProps = {
  to: '',
};

export default SmartLink;
