import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { compose } from 'ramda';
import { BLACK, LIGHT_GRAY_1, MEDIUM_GRAY } from '../../constants/colors';
import { LAPTOP, TABLET, media } from '../../constants/screens';
import withReduxFormInput from '../../enhancers/withReduxFormInput';
import withCustomPlaceholder from '../../enhancers/withCustomPlaceholder';

const Dropdown = ({
  PlaceholderComponent, options, value, ...rest
}) => (
  <StyledSelect
    {...rest}
    value={options.find(({ value: v }) => v === value)}
    options={options}
    placeholder={PlaceholderComponent}
  />
);

Dropdown.propTypes = {
  PlaceholderComponent: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.node, PropTypes.string]).isRequired,
};

Dropdown.defaultProps = {
  options: [],
  PlaceholderComponent: null,
};

const StyledSelect = styled(Select)`
  width: 100%;
  box-sizing: border-box;
  font-family: AvenirNextLTProBold;
  font-size: 21px;
  line-height: normal;
  
  & input {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
  }
  
  & > div {
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid ${MEDIUM_GRAY};
    background-color:  ${LIGHT_GRAY_1};
    min-height: 70px;
    
    ${media.laptop`
      min-height: 55px;
    `}
    
    &:nth-of-type(1) {
      box-shadow: none;
      padding-left: 25px;
      ${({ style }) => style};
          
      &:hover,
      &:focus {
        border: 1px solid ${MEDIUM_GRAY};
        box-shadow: none;
        ${({ style }) => style};
      }
      
      & > div:first-child {
        & > div {
          margin: 0;
          width: 100% !important;
        }
      }
    }
    
    &:nth-of-type(2) {
      border: none;
      
      & div[id*="react-select"] {
        display: flex;
        align-items: center;
        padding-left: 25px;
        color: ${BLACK};
        background-color:  ${LIGHT_GRAY_1};
        min-height: 70px;
        
        &:hover {
          background-color:  ${MEDIUM_GRAY};
        }
      }
    }
    
    & > div:nth-child(2) {
      & span {
        background-color: ${MEDIUM_GRAY};
      }
    }
    
    & > div:first-child {
      padding: 0;
    }
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
`;

export default compose(
  withReduxFormInput({ getValue: ({ value }) => value }),
  withCustomPlaceholder(),
)(Dropdown);
