import React from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import { DARK_GRAY, LIGHT_GRAY_1, MEDIUM_GRAY } from '../../constants/colors';
import { LAPTOP, TABLET } from '../../constants/screens';
import withReduxFormInput from '../../enhancers/withReduxFormInput';
import withCustomPlaceholder from '../../enhancers/withCustomPlaceholder';
import { getValueWithConstrains } from '../../utils/form';

const TextField = props => (
  <TextFieldInput
    {...props}
  />
);

TextField.propTypes = {
  type: PropTypes.string,
};

TextField.defaultProps = {
  type: undefined,
};

const TextFieldInput = styled.input`
  width: 100%;
  min-height: 70px;
  padding-left: 25px;
  border-radius: 6px;
  border: 1px solid ${MEDIUM_GRAY};
  background-color:  ${LIGHT_GRAY_1};
  outline: none;
  box-sizing: border-box;
  font-family: AvenirNextLTProBold;
  font-size: 21px;
  line-height: normal;
  margin-bottom: 15px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
    min-height: 55px;
  }
  
  &::-webkit-input-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  &::-moz-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  &:-ms-input-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  &:-moz-placeholder {
    font-family: AvenirNextLTProBold;
    font-size: 21px;
    color: ${DARK_GRAY};
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
  }
  
  ${({ inputStyles }) => inputStyles}
`;

export default compose(
  withReduxFormInput({ getValue: getValueWithConstrains }),
  withCustomPlaceholder(),
)(TextField);
