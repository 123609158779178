import { compose } from 'ramda';
import { oneRequired } from './validate';

export const encode = data => Object.keys(data)
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

export const getValueWithConstrains = ({ target: { value } }, prevValue, { constraints }) => {
  if (constraints) {
    return compose(
      ({ value: v }) => v,
      ...constraints,
    )({ value, prevValue });
  }
  return value;
};

export const numberOnly = ({ value, prevValue }) => {
  if (/^\d+$/.test(value) || !value) {
    return { value, prevValue };
  }
  return { prevValue };
};

export const validateInterests = (values, formData) => {
  const { checkboxes: { required, formFields } } = formData;
  if (!required) return () => {};
  return oneRequired(
    formFields.reduce(
      (r, f) => ({ interests: { ...r.interests, [f.htmlName]: values[f.htmlName] } }),
      {},
    ),
  );
};

export const getValidationSchema = (formData) => {
  const { formFields } = formData;
  return formFields.reduce((r, { htmlName, required }) => ({
    ...r,
    [htmlName]: required ? { presence: true, email: htmlName === 'email' } : undefined,
  }), {});
};
