import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  border: none;
  
  &:focus {
    outline: 0;
  }
  
  &:hover {
    cursor: pointer;
  }
`;

export default Button;
