import { createStore as reduxCreateStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';
import reducers from './reducers';

const initialState = {};

export const createStore = () => reduxCreateStore(
  reducers,
  initialState,
  devToolsEnhancer(),
);
