import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { media } from '../../constants/screens';
import { TURQUOISE, WHITE } from '../../constants/colors';

const BackToTop = () => {
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const { pageYOffset } = window;
      const threshold = 150;
      const newVisible = pageYOffset > threshold;

      if (newVisible !== visible) {
        setVisibility(newVisible);
      }
    }, 100);

    window.addEventListener(
      'scroll',
      handleScroll,
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visible]);

  const scrollToTop = () => {
    if (typeof document !== 'undefined') {
      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Button
      style={{ opacity: visible ? 1 : 0 }}
      onClick={scrollToTop}
    >
      Back to top
    </Button>
  );
};

const Button = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  bottom: 50px;
  right: 30px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background: ${TURQUOISE};
  font-size: 0;
  outline: none;
  box-shadow: -3px 5px 20px 6px rgba(155, 155, 155, 0.17);
  transition: opacity 0.3s ease;
  
  &:after {
    display: block;
    content: '';
    height: 10px;
    width: 10px;
    border-left: 3px solid ${WHITE};
    border-bottom: 3px solid ${WHITE};
    transform: rotate(135deg) translate(15%, -15%);
  }
  
  ${media.tablet`
    display: flex;
  `}
  
  ${media.mobile`
    right: 15px;
  `} 
`;

export default BackToTop;
