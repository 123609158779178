// properties for "src/layouts/index.jsx" 'react-helmet' module
export const pathnameToPageSEOMeta = {
  '/': {
    title: 'SlashData - the leading analyst in the developer economy.',
    meta: [
      { name: 'description', content: 'The analyst of the developer economy. We talk to developers and help the world’s top developer-facing firms understand their audience & measure their strategies’​ ROI.' },
    ],
  },
  '/about': {
    title: 'About SlashData',
    meta: [
      { name: 'description', content: 'The analyst of the developer economy. We talk to developers and help the world’s top developer-facing firms understand their audience & measure their strategies’​ ROI.' },
      { name: 'keywords', content: 'slashdata, about slashdata' },
    ],
  },
  '/careers': {
    title: 'Careers at SlashData',
    meta: [
      { name: 'description', content: 'Join our team at SlashData and help the world’ leading tech firms understand developers through research and data.' },
      { name: 'keywords', content: 'slashdata jobs, slashdata careers, slashdata values, slashdata culture' },
    ],
  },

  '/contact-us': {
    title: 'Contact - SlashData',
    meta: [
      { name: 'description', content: 'Questions about our research or methodology? Want to learn more about our company and vision for the future? Just drop us a line.' },
      { name: 'keywords', content: 'slashdata, slashdata research, contact slashdata, slashdata analyst ' },
    ],
  },
  '/methodology': {
    title: 'Methodology - SlashData',
    meta: [
      { name: 'description', content: 'Nine (9) essential and non negotiable qualities that run through our methodology model.' },
      { name: 'keywords', content: 'survey methodology, market survey, developer sample, developer data, developer panel, survey panel' },
    ],
  },
  '/developer-research': {
    title: 'Developer research - SlashData',
    meta: [
      { name: 'description', content: 'SlashData developer research allows you to extract charts and tables, produce forecasts, see how your developer offerings stack up against the competition and design your growth strategy.' },
      { name: 'keywords', content: 'developer research, developer data, research products, developer market trends, technology research, data dashboards, developer satisfaction tracker, developer program benchmarking slashdata, slashdata research' },
    ],
  },
  '/developer-program-benchmarking': {
    title: 'Developer Program Benchmarking - SlashData',
    meta: [
      { name: 'description', content: 'Developer Program Benchmarking tracks how the leading developer programs compare and what developers expect from tech leaders. Twice per year, we measure developer satisfaction across the industry’s 20+ leading developer programs on 20+ program features and services, including marketing, documentation, training, events, and more.' },
      { name: 'keywords', content: 'developer program benchmarking, developer programs, developer research, developer satisfaction, slashdata benchmarking, slashdata' },
    ],
  },
  '/developer-satisfaction-tracker': {
    title: 'Developer Satisfaction Tracker - SlashData',
    meta: [
      { name: 'description', content: 'Developer Satisfaction Tracker helps you Identify market gaps, fine-tune your product features and beat the competition based on the hard data from 40,000+ developers surveyed annually. Find out when and why developers choose competitor products - and how to fix that.' },
      { name: 'keywords', content: 'developer satisfaction tracker, developer tracker, developer research, market tracker, software vendor tracker, slashdata, slashdata tracker' },
    ],
  },
  '/reports': {
    title: 'Developer Insights Reports - SlashData',
    meta: [
      { name: 'description', content: 'Developer Insights Reports cover all platforms, technologies and developer segments, from mobile, IoT, cloud, and desktop to games, AR/VR and machine learning.' },
      { name: 'keywords', content: 'developer insights, developer insights reports, developer report, developer population, developer ecosystem, developer research, developer trends, developer landscape, SlashData, SlashData reports' },
    ],
  },
  '/free-resources': {
    title: 'Free Resources - SlashData',
    meta: [
      { name: 'description', content: 'Download free developer trends reports with insights from 40,000+ software developers, in over 150 countries across all platforms, technologies and developer segments.' },
      { name: 'keywords', content: 'Slashdata free report, developer economics report, state of developer nation, developer economics, slashdata, developer atlas, developer trends' },
    ],
  },
  '/data-dashboards': {
    title: 'Data Dashboards - SlashData',
    meta: [
      { name: 'description', content: 'Data dashboards allow you visualise data to spot new business opportunities. Drill down the data by region, app category, professionals vs. hobbyists, target audience etc, track trends over time, extract graphs and charts.' },
      { name: 'keywords', content: 'Data dashboards, developer data, slashdata dashboards' },
    ],
  },
};

// properties for  "src/components/info.jsx" component (heading html tags controll)
export const pageNameToSeoTagTypes = {
  index: {
    InfoSubTitle: 'h1',
    InfoText: 'h2',
  },
  careers: {
    InfoSubTitle: 'h1',
    InfoText: 'h2',
  },
  'developer-research': {
    InfoSubTitle: 'span',
    InfoText: 'h2',
    HeadingWrapper: 'h1',
  },
  'developer-program-benchmarking': {
    InfoSubTitle: 'h1',
    InfoText: 'h2',
  },
  'developer-satisfaction-tracker': {
    InfoSubTitle: 'h1',
    InfoText: 'h2',
  },
  reports: {
    InfoSubTitle: 'h1',
    InfoText: 'h2',
    HeadingWrapper: 'div',
  },
  'data-dashboards': {
    InfoSubTitle: 'h1',
    InfoText: 'h2',
  },
};
