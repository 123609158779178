import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import onClickOutside from 'react-onclickoutside';
import {
  BLACK, BLUE, LIGHT_GRAY_2, TURQUOISE, WHITE,
} from '../../constants/colors';
import ArrowIcon from '../../svg/arrow.svg';
import MenuButton from '../buttons/menuButton';
import { TABLET, media, LAPTOP } from '../../constants/screens';
import DivArrow from './arrow';
import SmartLink from '../common/smartLink';

class LinkMenu extends React.Component {
  constructor() {
    super();
    this.state = { open: false, height: 0 };
  }

  handleClickOutside = () => {
    this.setState({ open: false, height: 0 });
  }

  handleChange = () => {
    const { onChange } = this.props;
    this.setState({ open: false, height: 0 });
    onChange();
  }

  onMenuButtonClick = () => {
    const { open } = this.state;
    const height = !open ? 'auto' : 0;
    this.setState({ open: !open, height });
  }

  render() {
    const {
      title, links, color, menuStyle, arrowColor,
    } = this.props;

    const { open, height } = this.state;

    return (
      <Root open={open}>
        <MenuButton color={color} onClick={this.onMenuButtonClick} open={open}>
          {title}
          {arrowColor ? <Arrow open={open} color={arrowColor} /> : null}
        </MenuButton>
        <Desktop>
          <AnimateHeight
            duration={0}
            height={height}
          >
            <DivArrow hidden={!open} width={50} height={50} color="#fff" direction="up" top={10} left={30} />
            <Menu open={open} showShadow={arrowColor === '#2b55c5'} style={menuStyle || {}}>
              {links.map(({ id, url, title: label }) => (
                <StyledLink
                  key={id}
                  to={url}
                  data-title={label}
                  onClick={this.handleChange}
                >
                  {label}
                </StyledLink>
              ))}
              <MenuTitle>{title}</MenuTitle>
            </Menu>
          </AnimateHeight>
        </Desktop>
        <Mobile>
          <AnimateHeight
            duration={500}
            height={height}
          >
            <DivArrow width={50} height={50} color="#fff" direction="up" top={10} left={30} />
            <Menu open={open} style={menuStyle || {}}>
              {links.map(({ id, url, title: label }) => (
                <StyledLink key={id} to={url} data-title={label} onClick={this.handleChange}>
                  {label}
                </StyledLink>
              ))}
              <MenuTitle>{title}</MenuTitle>
            </Menu>
          </AnimateHeight>
        </Mobile>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  border-radius: 5px;
  
  @media(max-width: ${TABLET}) {
    margin-bottom: 10px;
    box-shadow: ${props => (props.open ? '0 0 27px 0 rgba(52, 75, 152, 0.18)' : 'none')};
  }
`;

const Desktop = styled.div`
  @media(max-width: ${TABLET}) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  
  @media(max-width: ${TABLET}) {
    display: block;
  }
`;

const Menu = styled.div`
  min-width: 250px;
  height: auto;
  overflow: hidden;
  position: absolute;
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-decoration: none;
  background: ${WHITE};
  padding: 20px 0;
  margin-top: 30px;
  box-sizing: border-box;
  ${props => props.showShadow && 'box-shadow: 0px 6px 49.1px 4.9px rgba(0,0,0,0.2);'}

  @media(min-width: ${TABLET}) {
    right: 0;
    border-radius: 10px;
    a {
      padding: 15px 40px;
    }
  }
  
  @media(min-width: ${LAPTOP}) {
    a {
      padding: 20px 70px;
    }
  }
  
  @media(max-width: ${TABLET}) {
    width: auto;
    position: relative;
    padding: 10px;
    margin-top: 0px;
    background-color: ${LIGHT_GRAY_2};
  }
  
  @media screen and (min-width: 1720px) and (max-width: 1920px) {
    min-width: 400px;
  }
  
  @media screen and (min-width: 1920px) {
    min-width: 500px;
  }
`;

const StyledLink = styled(SmartLink)`
  font-family: AvenirNextLTPro;
  padding: 12px 12px 12px 18px;
  text-decoration: none;
  white-space: nowrap;
  font-size: 19px;
  color: ${BLACK};
  display: flex;
  flex-direction: column;
  
  ${media.laptop`
    font-size: 16px;
    padding: 8px 8px 8px 12px;
  `};
  
  &:before {
    content: attr(data-title);
    font-family: AvenirNextLTProBold;
    visibility: hidden;
    height: 0;
  }
  
  &:hover {
    font-family: AvenirNextLTProBold;
    background-color: ${BLUE};
    color: ${WHITE};
  }
  
  @media(max-width: ${TABLET}) {
    padding: 20px 20px 20px 30px;
    font-size: 14px;
    font-weight: 800;
  }
`;

const Arrow = styled(ArrowIcon)`
  margin-left: 5px;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: 14px;
  height: 8px;
  & path {
    fill: ${({ color }) => color};
  }
`;

const MenuTitle = styled.div`
  text-transform: uppercase;
  color: ${TURQUOISE};
  display: flex;
  justify-content: flex-end;
  padding: 20px 70px 0px 70px;
  min-width: 150px;
  font-family: BrandonGrotesque;
  font-size: 30px;
  
  @media(max-width: ${TABLET}) {
    padding-bottom: 20px;
    display: none;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    padding: 15px 40px 0px 40px;
    font-size: 24px;
  }
`;

LinkMenu.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  menuStyle: PropTypes.shape(),
  arrowColor: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

LinkMenu.defaultProps = {
  menuStyle: null,
  onChange: () => {},
};

export default onClickOutside(LinkMenu);
