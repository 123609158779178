import ReactGA from 'react-ga';
import { pathOr, contains } from 'ramda';

export const initGA = (env) => {
  ReactGA.initialize(env.GA_TRACKING_ID, {
    debug: contains('.netlify.com', pathOr('', ['location', 'origin'], window)),
  });
};

export const ga = eventProps => ReactGA.event(eventProps);

export const { pageview, set } = ReactGA;

function getEventCategoryByPathname() {
  const pathnameToCategory = {
    '/reports': 'Paid reports',
    '/free-resources': 'Free reports',
    '/data-dashboards': 'Dashboards',
    '/developer-program-benchmarking': 'DPBs',
    '/developer-satisfaction-tracker': 'DSTs',
  };

  const pathname = typeof window !== 'undefined'
    ? window.location.pathname
    : undefined;

  return pathnameToCategory[pathname]
    ? pathnameToCategory[pathname]
    : 'Unknown category';
}

export const track404Page = location => ga(
  {
    category: getEventCategoryByPathname(),
    action: 'Page Not Found',
    label: `User tried to access: ${location}`,
  },
);

export const trackShowDescriptionClick = (detailsTitle) => {
  ga({
    category: getEventCategoryByPathname(),
    action: 'See what’s inside [click]',
    label: detailsTitle,
  });
};

export const trackGoToDownloadPage = (isFreeReport, detailsTitle) => {
  ga({
    category: getEventCategoryByPathname(),
    action: `Download ${isFreeReport ? 'report' : 'brouchure'} [btn]`,
    label: detailsTitle,
  });
};

export const trackFormSubmission = (formName, pageType, brochureTitle, reportType) => {
  let action;
  switch (pageType) {
    case 'dataDashboards':
      action = {
        category: 'Dashboards',
        action: 'Brochure downloaded',
      };
      break;
    case 'developerProgramBenchmarking':
      action = {
        category: 'DBPs',
        action: 'Brochure downloaded',
      };
      break;
    case 'trackers':
      action = {
        category: 'Technology-trackers',
        action: 'Brochure downloaded',
      };
      break;
    case 'reports':
      action = {
        category: `${reportType === 'free' ? 'Free' : 'Paid'} reports`,
        action: `${reportType === 'free' ? 'Report' : 'Brochure'} downloaded`,
      };
      break;
    default:
      action = {
        category: 'Unknown category',
        action: 'Unknown action',
      };
      break;
  }

  let event;
  switch (formName) {
    case 'request-brochure':
    case 'request-free-report':
      event = {
        ...action,
        label: brochureTitle,
      };
      break;
    case 'contact-sales':
    case 'subscribe-free-reports':
      event = {
        category: getEventCategoryByPathname(),
        action: 'Subscription form submitted',
        label: `${getEventCategoryByPathname()} inquiry`,
      };
      break;
    case 'contact':
      event = {
        category: 'Contact form',
        action: 'Contact form submitted',
        label: 'Contact form',
      };
      break;
    default:
      event = {
        category: 'Unknown category',
        action: `${formName} form submitted`,
        label: `${formName} form`,
      };
      break;
  }

  ga(event);
};
