import styled from 'styled-components';
import { WHITE } from '../../constants/colors';
import Button from './button';
import { TABLET, media } from '../../constants/screens';

const MenuButton = styled(Button)`
  background-color: transparent;
  font-size: 19px;
  color: ${({ color }) => color};
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 0;
  margin-right: 14px;
  
  ${media.laptop`
    font-size: 16px;
  `};
  
  & a {
    text-decoration: none;
    color: ${({ color }) => color};
  }
  
  @media(max-width: ${TABLET}) {
    width: 100%;
    margin: 0;
    min-height: 52px;
    padding: 4px 15px 0 25px;
    background-color: ${WHITE};
    box-shadow: ${props => (props.open ? 'none' : '0 0 14px 0 rgba(52, 75, 152, 0.18)')};
    border-radius: 3px;
    font-size: 16px;
    font-family: AvenirNextLTPro;
    font-weight: 800;
    color: #332f2f;
  }
`;

export default MenuButton;
