import { compose, flatten, find } from 'ramda';

export const mapObject = f => obj => Object.keys(obj).map(k => f(k, obj[k]));

export const reduceObject = (f, initilValue) => obj => Object.keys(obj).reduce(
  (res, k, i) => f(res, k, obj[k], i), initilValue,
);

export const mapConjunction = f => (...args) => args[0].map(
  (v, index) => f(...args.map(a => a[index]), index),
);

export const filterConjunction = f => (...args) => {
  const bools = mapConjunction((...innerArrgs) => !!f(...innerArrgs))(...args);
  return args.map(a => a.filter((v, i) => bools[i]));
};

export const filterObject = f => obj => Object.keys(obj).reduce(
  (res, key) => (f(obj[key], key) ? { ...res, [key]: obj[key] } : res), {},
);

export const isEmpty = obj => Object.keys(obj).reduce((res, k) => {
  if (res) {
    return typeof obj[k] === 'object' && obj[k] !== null
      ? isEmpty(obj[k])
      : obj[k] === undefined || obj[k] === null;
  }
  return res;
}, true);


export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  // eslint-disable-next-line
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const getQueryString = compose(
  stringifyQuery,
  filterObject(v => v !== null && v !== undefined),
);

export function stringifyQuery(query) {
  return Object.keys(query)
    .filter(k => !!k)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
    .join('&');
}

export function getSearchFromUrl(url) {
  if (url && url.length) {
    const search = url.match(/\?.+/);
    return search ? search[0] : '';
  }
  return '';
}

function capitalizeFirstLetter(str) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function getDataNameByUrl(url) {
  const location = url.split('/')[1];
  if (location === 'free-resources') return 'freeReports';
  if (location === 'technology-trackers') return 'trackers';
  return location
    .split('-')
    .reduce((res, chunk, i) => (i === 0 ? `${res}${chunk}` : `${res}${capitalizeFirstLetter(chunk)}`), '');
}

export function findIndexBy(array, by, value, dummy) {
  if (!array) return dummy;
  const index = array.findIndex(item => item[by] === value);
  return index !== -1 ? index : dummy;
}

export const findValueBy = (by, key, value) => compose(
  item => (item ? item[key] : null),
  find(i => i[by] === value),
  flatten,
);

export const trimOneEnd = (char, end) => (string) => {
  let stringValue = string;
  while (stringValue[end === 'start' ? 0 : stringValue.length - 1] === char) {
    stringValue = string.slice(end === 'start' ? 1 : 0, end === 'start' ? stringValue.length : stringValue.length - 1);
  }
  return stringValue;
};

export const trim = char => compose(
  trimOneEnd(char, 'end'),
  trimOneEnd(char, 'start'),
);
