import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Footer from './footer';

export default props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        footer {
          logoLink
          address {
            html
          }
          socials {
            _id
            alt
            url
            icon {
              localFile {
                publicURL
              }
            }
          }
          copyright {
            items {
              _id
              url
              title
            }
            title
          }
          menuItem {
            id: _id
            title
            url
            navLink {
              id: _id
              title
              url
            }
          }
        }
      }
    `}
    render={({ footer }) => <Footer {...props} data={footer} />}
  />
);
