import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {
  path, pathOr, isEmpty,
} from 'ramda';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Header from '../header';
import './index.css';
import '../../styles/cookiebot.css';
import { DARK_SCHEME, LIGHT_SCHEME, WHITE } from '../../constants/colors';
import Footer from '../footer';
import { getPropertyValue } from '../../utils/component';
import { pathnameToPageSEOMeta } from '../../utils/seo';
import { initGA } from '../../utils/analytics';
import { getQueryString, getSearchFromUrl, parseQuery } from '../../utils/common';
import BackToTop from '../common/backToTop';
import withLocation from '../../enhancers/withLocation';

const defaultLayoutContext = {
  scheme: DARK_SCHEME,
  fixHeader: false,
  isBlog: false,
  env: {},
  location: { search: '' },
  history: (typeof window !== 'undefined' ? window.history : { replaceState: () => null, pushState: () => null }),
  navigate: () => null,
  layoutUpdater: () => null,
};

const LayoutContext = createContext(defaultLayoutContext);

const { Provider, Consumer } = LayoutContext;

class Layout extends React.Component {
  static get propTypes() {
    return {
      location: PropTypes.shape().isRequired,
      navigate: PropTypes.func.isRequired,
      children: PropTypes.shape().isRequired,
      data: PropTypes.shape({
        site: PropTypes.shape({
          siteMetaData: PropTypes.shape({
            title: PropTypes.string,
          }),
        }),
      }).isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.updateLayout = this.updateLayout.bind(this);
    this.setFakeSearch = this.setFakeSearch.bind(this);
    this.state = {
      ...defaultLayoutContext,
      layoutUpdater: this.updateLayout,
      history: {
        ...defaultLayoutContext.history,
        replaceState: (...args) => {
          defaultLayoutContext.history.replaceState(...args);
          // need to set this because there is no way to update
          // string query without page reload by using @reach-router
          this.setFakeSearch(args[2]);
        },
        pushState: (...args) => {
          defaultLayoutContext.history.pushState(...args);
          // need to set this because there is no way to update
          // string query without page reload by using @reach-router
          this.setFakeSearch(args[2]);
        },
      },
    };
  }

  componentWillMount() {
    const env = JSON.parse(pathOr('{}', ['data', 'site', 'siteMetadata', 'env'], this.props));
    if (!isEmpty(env)) {
      this.updateLayout({ env });
    }

    if (typeof window !== 'undefined') {
      this.setFakeSearch(window.location.search);
    }
  }

  componentDidMount() {
    const { env } = this.state;

    if (typeof window !== 'undefined') {
      window.addEventListener('CookiebotOnAccept', () => {
        if (pathOr(false, ['Cookiebot', 'consent', 'statistics'], window)) {
          initGA(env);
        }
      });

      window.addEventListener('CookiebotOnDecline', () => {
        const cookieToDelete = ['_ga', '_gid'];
        const deleteCookie = (name) => {
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        };
        cookieToDelete.map(name => deleteCookie(name));
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    const { location: { search: fakeSearch }, history: { replaceState } } = this.state;
    const query = parseQuery(search);
    const newQuery = parseQuery(nextSearch);
    const fakeQuery = parseQuery(fakeSearch);
    replaceState(
      {},
      '',
      `${nextProps.location.pathname}?${getQueryString({ ...query, ...fakeQuery, ...newQuery })}`,
    );
  }

  setFakeSearch(query) {
    const search = getSearchFromUrl(query);
    this.setState(state => ({
      ...state,
      location: {
        ...state.location,
        search,
      },
    }));
  }

  updateLayout(newStateProp) {
    this.setState(state => ({
      ...state,
      ...newStateProp,
    }));
  }

  render() {
    const { children, location, navigate } = this.props;
    const {
      scheme,
      fixHeader,
      isBlog,
      env,
      location: stateLocation,
    } = this.state;

    // here we check if we render /404.jsx page
    const is404 = !!path(['props', 'data', 'image404'], children);
    const headerScheme = getPropertyValue([{ key: 'fixHeader', true: DARK_SCHEME }], scheme, this.state);

    return (
      <Provider value={{
        ...this.state,
        location: {
          ...location,
          ...stateLocation,
        },
        navigate,
      }}
      >
        <Root scheme={scheme}>
          <Helmet
            title={pathOr('SlashData', [location.pathname, 'title'], pathnameToPageSEOMeta)}
            meta={path([location.pathname, 'meta'], pathnameToPageSEOMeta)}
            script={[
              {
                id: 'Cookiebot',
                src: 'https://consent.cookiebot.com/uc.js',
                'data-cbid': env.COOKIEBOT_DOMAIN_GROUP_ID,
                type: 'text/javascript',
                async: true,
              },
              {
                src: 'https://www.google.com/recaptcha/api.js',
                type: 'text/javascript',
                'data-cookieconsent': 'necessary',
              },
            ]}
          />
          {
            !is404 && (
              <Header
                scheme={headerScheme}
                shouldBeFixed={fixHeader}
                isBlog={isBlog}
                env={env}
              />
            )
          }
          {children}
          {
            !is404 && (<Footer />)
          }
          <BackToTop />
        </Root>
      </Provider>
    );
  }
}

const Root = styled.div`
  position: relative;
  background-image: ${({ scheme }) => (scheme === LIGHT_SCHEME ? 'linear-gradient(126deg, #bfd0e1, #2a159a, #2a159a 0%, #2b55c5)' : WHITE)};
`;

const LayoutWithQuery = withLocation(({ data, ...props }) => (
  <StaticQuery
    query={graphql`
      query LayoutDataQuery {
        site {
          siteMetadata {
            title,
            env
          }
        }
      }
    `}
    render={d => <Layout {...props} data={{ ...data, ...d }} />}
  />
));

LayoutWithQuery.propTypes = {
  data: PropTypes.shape(),
};

LayoutWithQuery.defaultProps = {
  data: {},
};

export { LayoutWithQuery as default, Consumer as LayoutConsumer, LayoutContext };
