import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { bounceInLeft, slideInDown } from 'react-animations';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import LinkMenu from './linkMenu';
import Logo from '../common/logo';
import BurgerIcon from '../../svg/burger.svg';
import CloseIcon from '../../svg/close-header.svg';
import Icon from './icon';
import ArrowBack from '../../svg/arrow-back.svg';
import { LIGHT_GRAY_1, WHITE } from '../../constants/colors';
import {
  getButtonColor,
  getFontColor,
  getArrowColor,
  getLogoColors, getBackgroundColor,
} from './utils';
import {
  CONTAINER_WIDTH, TABLET, media, CONTAINER_LAPTOP_WIDTH,
} from '../../constants/screens';
import Banner from '../banner';
import SmartLink from '../common/smartLink';

const bounceInLeftAnimation = keyframes`${bounceInLeft}`;
const slideInDownAnimation = keyframes`${slideInDown}`;

class Header extends React.Component {
  constructor() {
    super();
    this.state = { expanded: false, bannerVisible: false };
  }

  componentDidMount() {
    // eslint-disable-next-line
    const { bannerData: { pagesToDisplay } } = this.props;
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.close);

      const routes = pagesToDisplay.map(({ url }) => url.replace(/\//g, ''));
      const pathName = window.location.pathname.replace(/\//g, '');
      const isBannerChecked = window.localStorage.getItem('slashDataBannerChecked');
      if (!!routes.length && routes.includes(pathName) && !isBannerChecked) {
        this.setState({ bannerVisible: true });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.close);
  }

  handleExpand = (value) => {
    const { expanded } = this.state;
    if (expanded !== value) { this.setState({ expanded: value }); }
  };

  open = () => this.handleExpand(true);

  close = () => {
    this.handleExpand(false);
  };

  onBannerCancelClick = () => {
    this.setState({ bannerVisible: false });
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('slashDataBannerChecked', 'true');
    }
  };

  onBannerJoinClick = () => {
    this.setState({ bannerVisible: false });
    const { bannerData: { link } } = this.props;
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('slashDataBannerChecked', 'true');
      window.location.href = link;
    }
  };

  render() {
    const logoColors = getLogoColors({ ...this.props, ...this.state });
    const color = getFontColor({ ...this.props, ...this.state });
    const arrowColor = getArrowColor({ ...this.props, ...this.state });
    const buttonColor = getButtonColor({ ...this.props, ...this.state });

    const {
      scheme,
      shouldBeFixed,
      isBlog,
      data: { logoLink, blogLogoLink, menuItem: items },
      bannerData,
    } = this.props;
    const { expanded, bannerVisible } = this.state;

    const menuItem = items.filter(path(['visibleIn', isBlog ? 'blogPart' : 'mainPart']));
    const { showBanner, title: bannerTitle } = bannerData;

    return (
      <Root scheme={scheme} expanded={expanded} shouldBeFixed={shouldBeFixed}>
        {
          showBanner && (
            <Banner
              onCancelClick={this.onBannerCancelClick}
              onJoinClick={this.onBannerJoinClick}
              bannerVisible={bannerVisible}
              title={bannerTitle}
            />
          )
        }
        <Content color={color}>
          <LogoContainer shouldBeFixed={shouldBeFixed} expanded={expanded}>
            <Logo
              colors={logoColors}
              shouldBeFixed={shouldBeFixed}
              isBlog={isBlog}
              logoLink={logoLink}
              blogLogoLink={blogLogoLink}
            />
            {
              expanded
                ? (
                  <Icon
                    color={buttonColor}
                    component={CloseIcon}
                    onClick={this.close}
                    shouldBeFixed={shouldBeFixed}
                    widthUnfixed="20px"
                    heightUnfixed="20px"
                  />
                )
                : (
                  <Icon
                    color={buttonColor}
                    component={BurgerIcon}
                    onClick={this.open}
                    shouldBeFixed={shouldBeFixed}
                    widthUnfixed="32px"
                    heightUnfixed="32px"
                  />
                )
            }
          </LogoContainer>
          <Links expanded={expanded}>
            {menuItem.map(({
              title, url, navLink, id,
            }) => (
              !url && navLink.length ? (
                <LinkMenu
                  key={id}
                  title={title}
                  color={color}
                  arrowColor={arrowColor}
                  onChange={this.close}
                  scheme={scheme}
                  links={navLink}
                />
              ) : (
                <StyledMenuButton key={id} color={color} onClick={this.close}>
                  <StyledLink to={url} color={color}>
                    <div>{title}</div>
                    <Arrow />
                  </StyledLink>
                </StyledMenuButton>
              )
            ))}
          </Links>
        </Content>
      </Root>
    );
  }
}

Header.propTypes = {
  scheme: PropTypes.string.isRequired,
  shouldBeFixed: PropTypes.bool.isRequired,
  isBlog: PropTypes.bool,
  data: PropTypes.shape().isRequired,
  bannerData: PropTypes.shape().isRequired,
};

Header.defaultProps = {
  isBlog: false,
};

const Root = styled.div`
  position: ${({ shouldBeFixed }) => (shouldBeFixed ? 'fixed' : 'absolute')};
  background-color: ${getBackgroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1100;
  width: 100%;
  top: 0;
  ${({ shouldBeFixed }) => (shouldBeFixed ? css`animation: ${slideInDownAnimation} 0.6s;` : '')}
  ${({ shouldBeFixed }) => (shouldBeFixed ? css`box-shadow: 0 0 29.8px 5.3px rgba(0,0,0, 0.1);` : '')}
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => (props.shouldBeFixed ? '100px' : '150px')};
  
  @media (max-width: ${TABLET}) {
    padding: 0 30px;
    margin: 0;
    height: ${props => (props.shouldBeFixed || props.expanded ? '70px' : '104px')};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: calc(${CONTAINER_WIDTH} + 10%);
  color: ${({ color }) => color};
  padding: 0 5%;
  box-sizing: border-box;
  
  ${media.laptop`
    max-width: calc(${CONTAINER_LAPTOP_WIDTH} + 10%);
  `};
  
  @media (max-width: ${TABLET}) {
    display: block;
    padding: 0;
  }
`;

const Links = styled.div`
  display: flex;
  z-index: 1100;
  
  @media (max-width: ${TABLET}) {
    animation: 1s ${bounceInLeftAnimation};
    display: ${({ expanded }) => (expanded ? 'block' : 'none')};
    background-color: ${LIGHT_GRAY_1};
    padding: 30px 15px 10px 15px;
    position: absolute;
    width: calc(100% - 30px);
    height: 1500px;
    overflow: hidden;
  }
`;

const StyledLink = styled(SmartLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  ${media.laptop`
    font-size: 16px;
  `};
`;

const Arrow = styled(ArrowBack)`
  display: none;
  transform: rotate(180deg);
  width: 13px;
  position: relative;
  left: -2px;
  top: -2px;
  @media (max-width: ${TABLET}) {
    display: block;
  }
`;

const StyledMenuButton = styled.div`
  display: flex;
  background-color: transparent;
  font-size: 19px;
  color: ${({ color }) => color};
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-right: 33px;
  
  &:last-of-type {
    margin-right: 0;
  }
  
  & a {
    text-decoration: none;
    color: ${({ color }) => color};
  }
  
  ${media.laptop`
  
  `};
  
  
  @media(max-width: ${TABLET}) {
    height: 48px;
    padding: 4px 15px 0 25px;
    background-color: ${WHITE};
    box-shadow: ${props => (props.open ? 'none' : '0 0 14px 0 rgba(52, 75, 152, 0.18)')};
    border-radius: 3px;
    font-size: 16px;
    font-family: AvenirNextLTPro;
    font-weight: 500;
    margin-bottom: 10px;
    margin-right: 0;
    a {
      color: #332f2f;
      font-weight: 800;
    }
  }
`;

export default Header;
